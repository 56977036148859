<!-- 文件中文名: 游轮游信息管理-->
<template>
  <div class="CruiseInformation">
    <app-breadcrumb/>
    <div class="page-container">
      <div class="filter">
        <el-form :inline="true" :model="listQuery" label-width="90px" size="mini">
          <el-form-item label="资格人编号:" class="memberNo-css input-red">
            <el-input v-model.trim="listQuery.qualifiedNo" style="width:160px;" clearable/>
          </el-form-item>
          <el-form-item style="margin-left:5px;">
            <el-button type="primary" plain icon="el-icon-search" @click="handleFilter">查询</el-button>
            <el-button type="primary" icon="el-icon-plus" @click="handleViewEdit({id:null}, 'new')">录入信息</el-button>
            <el-popover trigger="hover">
              <el-table :data="gridData" border max-height="200">
                <el-table-column width="120" prop="memberNo" label="经销商编号" align="center"></el-table-column>
                <el-table-column prop="qualifications" label="剩余资格数" align="center"></el-table-column>
              </el-table>
              <el-button v-if="isAgent" slot="reference" style="margin-left: 10px">剩余资格总数: {{remainingQty}}</el-button>
            </el-popover>
          </el-form-item>
        </el-form>
      </div>
      <el-table ref="memberTable" v-loading="doLoading" :data="list" style="width: 100%" size="mini" border stripe>
        <el-table-column
            label="操作"
            align="center"
            fixed
            width="120">
          <template slot-scope="scope">
            <el-button title="查看" size="mini" icon="el-icon-search" circle
                       @click="handleViewEdit(scope.row, 'view')"/>
            <el-button title="编辑" size="mini" icon="el-icon-edit" type="warning" circle
                       @click="handleViewEdit(scope.row, 'edit')" v-if="scope.row.creater===userCode&&scope.row.checkStatus!=='1'"/>
            <el-button title="删除" size="mini" icon="el-icon-delete" type="danger" circle
                       @click="handleDelete(scope.row)" v-if="scope.row.creater===userCode&&scope.row.checkStatus!=='1'"/>
          </template>
        </el-table-column>
        <el-table-column label="审核状态" prop="checkStatus" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.checkStatus | checkStatusFilter }}</span>
          </template>
        </el-table-column>
        <el-table-column label="驳回原因" align="center">
          <template slot-scope="scope">
            <span style="color: red">{{ scope.row.cancelRemark }}</span>
          </template>
        </el-table-column>
        <el-table-column label="资格人编号" prop="qualifiedNo" align="center"/>
        <el-table-column label="出行人姓名" prop="memberName" align="center"/>
        <el-table-column label="出行人性别" prop="sex" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.sex | sexFilter }}</span>
          </template>
        </el-table-column>
        <el-table-column label="出行人手机号" prop="mobile" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.mobile | mobileFilter }}</span>
          </template>
        </el-table-column>
        <el-table-column label="出行人身份证号" prop="idCard" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.idCard | idCardFilter }}</span>
          </template>
        </el-table-column>
        <el-table-column label="出行人护照号" prop="passport" align="center"/>
      </el-table>
      <pagination :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getList"/>
    </div>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import {checkNumberQual, memberPassportDropById, memberPassportPageByDTO} from "@/api/mi/memberPassport";
import {mapGetters} from "vuex";

export default {
  name: 'CruiseInformation',
  // import引入的组件需要注入到对象中才能使用",
  components: {Pagination},
  // 作为子组件传入数据
  props: {},
  data() {
    return {
      remainingQty: 0,
      gridData: [],
      list: [],
      doLoading: false,
      total: 0,
      listQuery: {
        qualifiedNo: null,
        page: 1,
        limit: 10
      },
    }
  },
  filters: {
    mobileFilter(value) {
      return value.slice(0, 3) + '****' + value.slice(-4);
    },
    idCardFilter(value) {
      return value.slice(0, 3) + '************' + value.slice(-4);
    },
    sexFilter(value) {
      return value === '1' ? '男' : '女'
    },
    checkStatusFilter(value) {
      return value === '0' ? '未审核' : value === '1' ? '审核通过' : '审核驳回'
    }
  },
  computed: {
    ...mapGetters([
      'isAgent',
      'userCode',
    ]),
  },
  watch: {},
  beforeCreate() {},
  created() {
    this.getList()
  },
  beforeMount() {},
  mounted() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  activated() {},
  methods: {
    async getList() {
      try {
        this.doLoading = true
        const res1 = await memberPassportPageByDTO({...this.listQuery})
        this.list = res1.data.records
        this.total = +res1.data.total || 0
        if(this.isAgent) {
          const res2 = await checkNumberQual()
          this.gridData = res2.data
          this.remainingQty = 0
          this.gridData.forEach(item=>{
            this.remainingQty += Number(item.qualifications)
          })
        }
        this.doLoading = false
      } catch (err) {
        console.error(err)
        this.doLoading = false
      }
    },
    handleFilter() {
      this.listQuery.page = 1
      this.getList()
    },
    handleViewEdit(row, type) {
      sessionStorage.setItem(
          'CruiseInfo',
          JSON.stringify({
                id: row.id,
                status: type
              }
          )
      )
      this.$router.push('/order/cruise-information-save')
    },
    handleDelete(row) {
      this.$confirm('是否确认删除?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.doLoading = true
        memberPassportDropById({id: row.id}).then(res => {
          this.doLoading = false
          if (res.success) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.getList()
          }
        }).catch(err => {
          this.doLoading = false
          console.error(err)
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      })
    },
  }
}

</script>

<style scoped>

</style>
